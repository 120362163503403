<template>
  <div v-if="user && $store.state.settings.init" class="single-user">
    <a @click="$router.go(-1)" class="back-link">
      <span class="material-icons">arrow_back</span>
      <span class="text">Back</span>
    </a>
    <h1>{{ user.email }}</h1>
    <button
      v-if="
        user.registerAccepted !== undefined && user.registerAccepted === false
      "
      @click="approveUser"
    >
      Approve user
    </button>
    <!-- <button @click="fetchCompanies">Fetch</button> -->
    <div class="single-user-meta">
      <div class="table-header-small">Info</div>
      <div class="admin-table horizontal single-user-meta-table">
        <div>
          <span class="key">name</span>
          <span class="value">{{ user.name }}</span>
        </div>
        <div>
          <span class="key">email</span>
          <span class="value">{{ user.email }}</span>
        </div>
        <div>
          <span class="key">phone</span>
          <span class="value">{{ user.phone }}</span>
        </div>
        <div>
          <span class="key">title</span>
          <span class="value">{{ user.title }}</span>
        </div>
        <div>
          <span class="key">role</span>
          <span class="value">{{ userRoles[user.role].name }}</span>
        </div>
        <div>
          <span class="key">uid</span>
          <span class="value">{{ user.id }}</span>
        </div>
        <div>
          <span class="key">vismaId</span>
          <span class="value">{{ user.vismaId }}</span>
        </div>
        <div style="cursor: pointer" @click="navigateToCompany(user.company)">
          <span class="key">company</span>
          <span class="value" v-if="user.companies.length < 2">{{
            getCompanyNameByVismaId(user.companyId)
          }}</span>
          <span class="value" v-else>
            <span
              v-for="(companyId, index) in user.companies"
              :key="user.id + companyId"
            >
              {{ getCompanyNameByVismaId(companyId) }}
              <span
                style="padding: 0 10px"
                v-if="index !== user.companies.length - 1"
              >
                &&
              </span>
            </span>
          </span>
        </div>
      </div>

      <div class="set-password-wrapper" :class="{ loading: loading }">
        <button v-if="!passwordForm" @click="passwordForm = true">
          Set password
        </button>
        <div class="password-form" v-else>
          <input type="text" placeholder="Password" v-model="password" />
          <button @click="setPassword">Set</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import firebase from "firebase/app";

export default {
  name: "SingleUser",
  data() {
    return {
      init: false,
      passwordForm: false,
      loading: false,
      password: "",
    };
  },
  methods: {
    setPassword: async function () {
      if (this.loading) {
        return;
      }

      if (this.password.length < 6) {
        alert("Password must be at least 6 characters long");
        return;
      }

      this.loading = true;

      var adminMassSendChatMessage = firebase
        .functions()
        .httpsCallable("adminSetPassword");

      const result = await adminMassSendChatMessage({
        userId: this.user.id,
        password: this.password,
      });

      console.log(result.data);

      if (result.data.status === 200) {
        this.loading = false;
        this.passwordForm = false;
        this.password = "";
        this.$toast.center(
          "Password set for " + this.user.email + " successfully"
        );
      } else {
        this.loading = false;
        this.$toast.center(
          "Error setting password. Code: " + result.data.status
        );
      }
    },
    navigateToCompany: function (id) {
      if (!id) {
        return;
      }
      this.$router.push({ name: "SingleCompany", params: { id } });
    },
    approveUser: function () {
      let confirm = window.confirm("Approve user " + this.user.email + "?");
      if (confirm) {
        this.$store.dispatch("users/approveUser", this.$route.params.id);
      }
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.users.singleUser,
    }),
    ...mapGetters("settings", ["userRoles", "getCompanyNameByVismaId"]),
  },
  created() {
    this.$store
      .dispatch("users/bindSingleUser", { id: this.$route.params.id })
      .then(() => {
        this.$nextTick(() => {
          console.log(this.user);
          this.init = true;
        });
      });
  },
};
</script>
